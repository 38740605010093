import * as React from "react";
import ProjectHeader from "../ProjectHeader";
import sketch from "../assets/images/crying-crocs/sketches.jpeg";
import croc3D from "../assets/images/crying-crocs/3Dcroc.png";
import cryingCrocGif from "../assets/images/crying-crocs/CryingCrocGif_v2.gif";
import ImageWithLoader from "../ImageWithLoader";
const CryingCrocs = () => (
  <div className="container py-4">
    <ProjectHeader title="Crying Crocs" />
    <div className="row justify-content-center align-items-center bg-color-purple py-5 border-bottom-blue">
      <ImageWithLoader
        src={cryingCrocGif}
        style={{ width: "180px", height: "180px" }}
      />
    </div>
    <div className="row align-items-start justify-content-center bg-color-green border-bottom-orange">
      <div className="col-12 col-lg-6 col-xl-7 bg-color-neutral px-0">
        <div className="row">
          <div className="col py-4 px-5">
            <p>
              When the pandemic first started, I noticed there were countless
              ways (many including a particularly audacious{" "}
              <a href="https://cultofthepartyparrot.com/">party animal</a>) to
              express happy emotions through slack emojis but very few for
              expressing sadness.
            </p>
            <p>
              I decided to start a collection of animated gifs expressing a
              diverse set of (mostly trivial) sad emotions. Check out the crocs{" "}
              <a href="https://lshap.github.io/crying_crocs/">here</a>.
            </p>
          </div>
        </div>
        <ImageWithLoader src={croc3D} className="w-100" />
      </div>
      <div className="col-12 col-lg-6 col-xl-5 px-0">
        <ImageWithLoader src={sketch} className="w-100" />
      </div>
    </div>
  </div>
);
export default CryingCrocs;

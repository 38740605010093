import * as React from "react";

const VideoWithLoader = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { src, className, style } = props;
  const videoRef = React.useRef(null);
  React.useEffect(() => {
    if (videoRef && videoRef.current && props.muted === true) {
      videoRef.current.muted = true;
      videoRef.current.setAttribute("muted", true);
    }
  }, [videoRef.current]);
  const videoStyle = isLoading ? { display: "none" } : {};

  return (
    <React.Fragment>
      {isLoading && (
        <div className={`img-loading ${className}`} style={style}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <video
        {...props}
        style={videoStyle}
        onLoadedData={() => setIsLoading(false)}
        ref={videoRef}
      >
        <source src={src} />
      </video>
    </React.Fragment>
  );
};

VideoWithLoader.defaultProps = {
  style: {},
  className: "",
  playsInline: true,
  autoPlay: true,
  loop: true,
};

export default VideoWithLoader;

import * as React from "react";
import ProjectHeader from "../ProjectHeader";
import popUp from "../assets/images/umbra/pop_up_trimmed.gif";
import slide from "../assets/images/umbra/slide.gif";
import smile from "../assets/images/umbra/smile.gif";

import paperPrototype from "../assets/images/umbra/early_prototype4.jpeg";
import paperPrototype2 from "../assets/images/umbra/early_prototype3.jpeg";
import shadowPrototypes from "../assets/images/umbra/shadow_prototypes.jpg";
import ImageWithLoader from "../ImageWithLoader";

const Umbra = () => (
  <div className="container">
    <div className="py-4">
      <ProjectHeader title="Umbra" />
      <div className="row bg-color-purple border-bottom-blue align-items-center">
        <div className="col-12 col-lg-8 col-xl-6">
          <div className="row justify-content-center align-items-center">
            <ImageWithLoader
              src={popUp}
              className="col px-0 h-100 img-responsive"
            />
          </div>
        </div>
        <div className="col-12 col-lg-4 col-xl-6">
          <div className="row justify-content-center">
            <p className="p-5">
              I love playing board &mdash;especially puzzle &mdash;games with my
              family and friends, and I've recently become interested in pop up
              books and how they are made. Umbra is a work-in-progress attempt
              to combine these two interests.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center align-items-center bg-color-neutral">
        <div className="col-md-5 col-12">
          <div className="row justify-content-center p-5">
            <p>
              I'm still developing the puzzle mechanics, but I'm interested in
              using cast shadows to communicate clues to the player. The game
              materials will include a physical pop up book and a flashlight.
            </p>
          </div>
        </div>
        <div className="col-md-7 col-12 justify-content-start align-items-center bg-color-orange px-0">
          <div className="row align-items-start">
            <div className="col-12 col-sm-8">
              <div className="row align-items-center">
                <ImageWithLoader
                  src={paperPrototype}
                  className="w-100 img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center bg-color-purple border-bottom-orange">
        <div className="col-lg-7 col-12 bg-color-green">
          <div className="row justify-content-end">
            <div className="col-12 col-sm-6">
              <div className="row align-items-center">
                <ImageWithLoader
                  src={slide}
                  className="w-100 p-0 img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-12 d-flex flex-column justify-content-center">
          <div className="row justify-content-center p-5">
            <p>
              The book tells the story of a person whose shadow becomes
              detatched from their body, and it is up to the player to help
              realign the shadow in order to make the character whole again.
            </p>
            <p>
              In coming up with the concept I was particularly inspired by the{" "}
              <a href="https://www.kosmosgames.co.uk/the-games/exit-games/">
                Exit
              </a>{" "}
              puzzle game series, and I wanted to explore ways that the physical
              form of a book could emphasize the theme of the puzzles it
              contains while relating a narrative.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center bg-color-neutral">
        <div className="col-md-5 col-12 p-5">
          <p>
            Blender has been a really useful prototyping tool for trying to
            figure out how to create cast shadows from different angles.
          </p>
        </div>
      </div>
      <div className="row justify-content-center bg-color-orange border-bottom-blue">
        <div className="col-12 col-sm-6">
          <div className="row align-items-center">
            <ImageWithLoader
              src={shadowPrototypes}
              className="w-100 p-0 img-responsive"
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-center align-items-center bg-color-purple">
        <div className="col-md-5 col-12">
          <div className="row justify-content-center">
            <p className="p-5">
              Alongside my shadow adventures, I've been learning about different
              paper mechanic techniques for pop up design from my new idol,{" "}
              <a href="https://www.youtube.com/channel/UCx2M2bGHtXBszG6tuR_NIbQ">
                Duncan Birmingham
              </a>
              .
            </p>
          </div>
        </div>
        <div className="col-md-7 col-12">
          <div className="row justify-content-start px-0 align-items-center">
            <ImageWithLoader src={smile} className="w-100 p-0" />
          </div>
        </div>
      </div>
      <div className="row justify-content-center align-items-center bg-color-neutral border-bottom-orange">
        <div className="col-md-6 col-12 align-items-center bg-color-green">
          <div className="row justify-content-end">
            <div className="col-sm-8 col-12">
              <div className="row align-items-center">
                <ImageWithLoader src={paperPrototype2} className="w-100 px-0" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="row justify-content-center">
            <div className="col-md-8 p-5">
              <p>
                I still have a lot to learn in the shadow and paper arts, but
                I'm excited to keep exploring!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Umbra;

import * as React from "react";

const AutoPlayVideo = ({ src, style, className, poster }) => {
  const videoRef = React.useRef(null);
  React.useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.setAttribute("muted", true);
    }
  }, [videoRef.current]);
  return (
    <video
      style={style}
      ref={videoRef}
      className={className}
      playsInline={true}
      autoPlay={true}
      poster={poster}
      loop
    >
      <source src={src} />
    </video>
  );
};

AutoPlayVideo.defaultProps = {
  style: {},
  className: "",
};

export default AutoPlayVideo;

import * as React from "react";
import ProjectHeader from "../ProjectHeader";
import bagelMeetsBagel from "../assets/images/bagel-meets-bagel/demo.mp4";
import bagelMeetsBagelPoster from "../assets/images/bagel-meets-bagel/bagel-meets-bagel.png";
import AutoPlayVideo from "../AutoPlayVideo";
import VideoWithLoader from "../VideoWithLoader";

const BagelMeetsBagel = () => (
  <div className="container py-4">
    <ProjectHeader title="Bagel Meets Bagel" />
    <div className="row justify-content-center align-items-center bg-color-purple border-bottom-blue">
      <div className="col-12 col-lg-6">
        <div className="row justify-content-center p-5">
          <p>
            Bagel Meets Bagel is a dating-style app for finding delicious bagels
            in your area.
          </p>
          <p>
            Last March, I found myself with some free time on my hands, and I
            was interested to learn about Swift UI and the Combine framework. I
            decided to get my hands dirty and built this prototype in about a
            week, relying on{" "}
            <a href="https://www.yelp.com/fusion">Yelp's Fusion API</a> to pull
            in the bagel date-a 🙃.
          </p>
        </div>
      </div>
      <div className="col-12 col-lg-6 bg-color-green">
        <div className="row justify-content-center">
          <VideoWithLoader
            src={bagelMeetsBagel}
            muted={true}
            className="w-50 p-0"
            poster={bagelMeetsBagelPoster}
          />
        </div>
      </div>
    </div>
  </div>
);
export default BagelMeetsBagel;

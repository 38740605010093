import * as React from "react";

const ImageWithLoader = ({ src, className, style }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const imageStyle = isLoading ? { display: "none" } : {};
  return (
    <React.Fragment>
      {isLoading && (
        <div className={`img-loading ${className}`} style={style}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <img
        src={src}
        style={{ ...style, ...imageStyle }}
        className={className}
        onLoad={() => setIsLoading(false)}
      />
    </React.Fragment>
  );
};

ImageWithLoader.defaultProps = {
  style: {},
  className: "",
};

export default ImageWithLoader;

import * as React from "react";
import "./App.css";
import Scene from "./Scene";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Planty from "./projects/Planty";
import CryingCrocs from "./projects/CryingCrocs";
import Umbra from "./projects/Umbra";
import BagelMeetsBagel from "./projects/BagelMeetsBagel";
import linkedInIcon from "./assets/images/linked_in.svg";

const Home = () => {
  const [displayLinks, setDisplayLinks] = React.useState(false);

  const toggleLinks = () => {
    setDisplayLinks(!displayLinks);
  };

  return (
    <div>
      {!displayLinks && <Scene />}
      <div className="header py-4">
        <div className="d-flex flex-row justify-content-end px-4 mb-3">
          <label className="switch">
            <input
              type="checkbox"
              defaultChecked={!displayLinks}
              onChange={toggleLinks}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <h1 className="header-content">Lauren Shapiro</h1>
            <div className="row justify-content-center">
              <div className="col-10 col-md-6 col-lg-5">
                <p className="mb-4 header-content">
                  Hi, I'm Lauren! I'm a software generalist interested in art
                  and ham-ination. Click {displayLinks ? "a link" : "a piggy"}{" "}
                  to see something weird I've been working on recently.
                </p>
                {displayLinks && (
                  <div>
                    <p>
                      <Link to="/planty" className="project-link">
                        Planty
                      </Link>
                    </p>
                    <p>
                      <Link
                        to="/umbra"
                        className={
                          displayLinks
                            ? "project-link"
                            : "project-link link-offset-1"
                        }
                      >
                        Umbra
                      </Link>
                    </p>
                    <p>
                      <Link
                        to="crying-crocs"
                        className={
                          displayLinks
                            ? "project-link"
                            : "project-link link-offset-2"
                        }
                      >
                        Crying Crocs
                      </Link>
                    </p>
                    <p>
                      <Link
                        to="/bagel-meets-bagel"
                        className={
                          displayLinks
                            ? "project-link"
                            : "project-link link-offset-2"
                        }
                      >
                        Bagel Meets Bagel
                      </Link>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer w-100">
        <div className="d-flex flex-row justify-content-center py-4">
          <a href="https://www.linkedin.com/in/lauren-shapiro-77822342">
            <img src={linkedInIcon} className="social-button-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/crying-crocs">
            <CryingCrocs />
          </Route>
          <Route path="/planty">
            <Planty />
          </Route>
          <Route path="/umbra">
            <Umbra />
          </Route>
          <Route path="/bagel-meets-bagel">
            <BagelMeetsBagel />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import * as THREE from "three";
import pigScene from "./assets/models/new_pig.glb";

const ANIMATION_NAMES = ["BootyShake", "Winking", "WeirdBreakDance", "Jumping"];
const PIGS_TO_PATHS = {
  PigBody: "/crying-crocs",
  PigBody005: "/planty",
  PigBody006: "/umbra",
  PigBody007: "/bagel-meets-bagel",
};

const Loading = () => (
  <div className="loading-container">
    <div className="container h-100">
      <div className="row justify-content-center h-100">
        <div className="loading-content p-3 text-center mx-auto my-auto">
          <div className="py-2">loading...</div>
          <marquee scrollamount="10">🐖 🐖 🐖 🐖 🐖 🐖 🐖 🐖</marquee>
        </div>
      </div>
    </div>
  </div>
);

function Scene() {
  const mountElement = useRef(null);
  const mouse = new THREE.Vector2(1, 1);
  const pigMaterialsByName = {};
  const highlightMaterial = new THREE.MeshNormalMaterial();
  highlightMaterial.skinning = true;
  const mixerRef = React.useRef(null);
  const animationClipsRef = React.useRef([]);
  const activePigRef = React.useRef(null);
  const history = useHistory();
  const prevTimeRef = React.useRef(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const frameIdRef = React.useRef(null);
  const sceneStyle = isLoading ? { display: "none" } : {};

  useEffect(() => {
    if (frameIdRef && frameIdRef.current) {
      return;
    }

    const mountEl = mountElement.current;
    const width = mountEl.clientWidth;
    const height = mountEl.clientHeight;
    let pigs;

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffcfd3);

    const directionalLight = new THREE.DirectionalLight(0xfff8b8, 0.3);
    scene.add(directionalLight);

    const light = new THREE.HemisphereLight(0xf2f2d8, 0x080820, 0.8);
    scene.add(light);
    const ambientLight = new THREE.AmbientLight(0xab9ba4);
    scene.add(ambientLight);

    const rectLight = new THREE.RectAreaLight(0xe6fcfc, 0.75, 10, 10);
    rectLight.position.set(5, 5, 0);
    rectLight.lookAt(0, 0, 0);
    scene.add(rectLight);

    const camera = new THREE.PerspectiveCamera(50, width / height, 0.1, 100);
    camera.position.set(0, 6, 35);
    camera.lookAt(0, 3, 0);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    const raycaster = new THREE.Raycaster();

    const loader = new GLTFLoader();

    const loadScene = (loader) => {
      return new Promise((resolve, reject) => {
        loader.load(
          pigScene,
          (gltf) => {
            resolve(gltf);
          },
          null,
          (error) => {
            reject(error);
          }
        );
      });
    };

    loadScene(loader).then((loadedGLTF) => {
      const loadedScene = loadedGLTF.scene;
      mixerRef.current = new THREE.AnimationMixer(loadedScene);
      const pigGroups = [];
      loadedScene.traverse((sceneEl) => {
        if (sceneEl.name.startsWith("PigBody")) {
          pigGroups.push(sceneEl);
        }
      });
      animationClipsRef.current = loadedGLTF.animations.filter(
        (a) => ANIMATION_NAMES.indexOf(a.name) >= 0
      );

      pigs = pigGroups.map((p) => p.children[0]).reverse();
      const firstPig = pigGroups[0].children[0];
      firstPig.onAfterRender = () => {
        if (isLoading) {
          setIsLoading(false);
        }
      };

      pigGroups.forEach((p) => {
        pigMaterialsByName[p.name] = p.children[0].material;
      });

      scene.add(loadedScene);

      playAnimations();
    });

    const playAnimations = () => {
      animationClipsRef.current.forEach((clip) => {
        const action = mixerRef.current.clipAction(clip);
        action.play();
      });
    };

    const renderScene = (time) => {
      raycaster.setFromCamera(mouse, camera);
      activePigRef.current = null;
      const dt = (time - prevTimeRef.current) / 1200;

      if (pigs && pigs.length) {
        pigs.forEach((p) => {
          p.material = pigMaterialsByName[p.parent.name];
        });

        const intersection = raycaster.intersectObjects(pigs);

        if (intersection.length > 0) {
          const instance = intersection[0];
          activePigRef.current = instance.object.parent.name;
          instance.object.material = highlightMaterial;
        }
      }
      mixerRef && mixerRef.current && mixerRef.current.update(dt);
      prevTimeRef.current = time;
      renderer.render(scene, camera);
    };

    const handleResize = () => {
      const newWidth = mountElement.current.clientWidth;
      const newHeight = mountElement.current.clientHeight;
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(newWidth, newHeight);
    };

    const animate = (time) => {
      renderScene(time);
      frameIdRef.current = requestAnimationFrame(animate);
    };

    const start = () => {
      if (!frameIdRef || !frameIdRef.current) {
        frameIdRef.current = requestAnimationFrame(animate);
      }
    };

    const stop = () => {
      cancelAnimationFrame(frameIdRef.current);
      frameIdRef.current = null;
    };

    mountElement.current.appendChild(renderer.domElement);
    window.addEventListener("resize", handleResize);
    document.addEventListener("mousemove", onMouseMove);
    renderer.setSize(width, height);
    start();

    return () => {
      stop();
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousemove", onMouseMove);
      mountEl.removeChild(renderer.domElement);
      // TODO: cleanup
    };
  }, [mountElement.current]);

  const onMouseMove = (event) => {
    if (mountElement && mountElement.current) {
      mouse.x = (event.clientX / mountElement.current.clientWidth) * 2 - 1;
      mouse.y = -(event.clientY / mountElement.current.clientHeight) * 2 + 1;
    }
  };

  const handleClick = () => {
    if (
      activePigRef &&
      activePigRef.current &&
      PIGS_TO_PATHS[activePigRef.current]
    ) {
      history.push(PIGS_TO_PATHS[activePigRef.current]);
    }
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <div
        ref={mountElement}
        className="scene"
        style={sceneStyle}
        onClick={handleClick}
      />
    </React.Fragment>
  );
}

export default Scene;

import * as React from "react";
import { Link } from "react-router-dom";

const ProjectHeader = ({ title }) => (
  <div>
    <Link to="/">🐷 Back</Link>
    <h1 className="text-center header-title">{title}</h1>
  </div>
);

export default ProjectHeader;

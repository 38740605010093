import * as React from "react";
import growth from "../assets/images/planty/growth.gif";
import rootity from "../assets/images/planty/rootity.gif";
import jealous from "../assets/images/planty/jealous.gif";
import demo from "../assets/images/planty/demo.mov";
import demoThumb from "../assets/images/planty/planty_demo.png";
import ImageWithLoader from "../ImageWithLoader";

import ProjectHeader from "../ProjectHeader";
import VideoWithLoader from "../VideoWithLoader";

const Planty = () => (
  <div className="container py-4">
    <ProjectHeader title="Planty" />
    <div className="row justify-content-center align-items-center bg-color-neutral border-bottom-blue">
      <div className="col-lg-6 col-12">
        <div className="row justify-content-center">
          <div className="p-5">
            <p>
              Planty is an AR app prototype that lets you grow, nurture, and
              just hang with a digital plant friend.
            </p>
            <p>
              Born out of frustration with my incompetence as a Plant Mom and a
              desire to mess around with AR development, Planty is the perfect
              way to get better as a plant parent without killing real life
              plants in the process (💀
              <span className="font-italic">
                {" "}
                RIP Monty, Louise, Günther, Cornelius...
              </span>
              ).
            </p>
            <p>
              Planty was built with ARKit and SwiftUI and the plant models and
              animations were created in Blender.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-12 bg-color-purple p-0">
        <div className="row justify-content-center">
          <div className="col-8 col-sm-5">
            <div className="row align-items-center justify-content-center">
              <ImageWithLoader src={growth} className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row border-bottom-blue">
      <div className="col-lg-6 col-12 bg-color-green">
        <div className="row justify-content-center">
          <div className="col-8 col-sm-5">
            <div className="row align-items-center justify-content-center">
              <ImageWithLoader src={rootity} className="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-12 bg-color-orange">
        <div className="row justify-content-center">
          <div className="col-8 col-sm-5">
            <div className="row align-items-center justify-content-center">
              <ImageWithLoader src={jealous} className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row bg-color-purple justify-content-center border-bottom-orange">
      <div className="col-lg-8 col-12 ">
        <div className="row justify-content-center">
          <div className="col-8 col-sm-5 offset-sm-3">
            <div className="row justify-content-end">
              <VideoWithLoader
                className="w-100"
                poster={demoThumb}
                muted={false}
                controls
                autoPlay={false}
                loop={false}
                src={demo}
              />
            </div>
          </div>
          <div className="col-3">
            <p className="text-small">
              Music: <span className="font-italic">HappyNES</span> by{" "}
              <a href="https://www.silvermansound.com">Shane Ivers</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Planty;
